@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.App {
	background-color: #2D076A;
	font-family: "Noto Sans JP", sans-serif;
	color: white;
}
.landing-page {
	padding-top: 2% !important;
	min-height: 100vh;
}

.nav {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.nav-link {
	text-decoration: none;
	/* font-weight: bold; */
	color: white;
	font-size: 1.3rem;
	margin: 0 20px;
}

.nav-link:hover {
	color: rgb(218, 218, 218);
}

.quote {
	margin-top: 15%;
}

.quote h2 {
	color: white;
	font-size: 3rem;
	margin: 0;
}

.quote .quotation {
	font-size: 5rem;
	color: #FFC4C4;
}

.quote h2 span {
	font-size: 3.4rem;
	color: #FFC4C4;
}

.jumbo-btn-div {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	margin-top: 30px;
}

.jumbo-btn {
	cursor: pointer;
	padding: 5% 10%;
	border: none;
	border-radius: 12px;
	font-size: 2rem;
	letter-spacing: 3px;
	color: #2D076A;
	background-color: #FFC4C4;
	font-weight: bold;
}
.login-page {
	height: 100vh;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
	background-repeat: no-repeat;
	background-position: 0% 100%;
	background-size: 100%;
	background-image: url(/static/media/login-bg.0a9aa96b.svg), linear-gradient(110.27deg, #3e00a3 0%, #05000e 100%);
	font-family: "Noto Sans JP";
}

.tagline {
	font-family: "Noto Sans JP";
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: 41px;
	letter-spacing: 0em;
	text-align: left;
	margin: 120px auto auto 100px;
}

.tagline p {
	display: block;
	font-size: 60px;
	margin: 5px 0;
	line-height: 82px;
}

.tagline p span {
	color: #df9ffe;
}

.text-field {
	background-color: #2c0078;
	border-radius: 12px;
	color: #fff;
	margin: 8px 0 !important;
}

.text-field input {
	color: #fff;
	outline: none;
	border: none;
	border-radius: 12px !important;
}

option {
	height: 20px;
	padding: 10px 15px;
}

.text-field div {
	border-radius: 12px !important;
	color: white;
}

.login-btn {
	width: 100%;
	background: linear-gradient(91.9deg, #d59fff 0%, #6a8bff 100%);
	border-radius: 12px;
	padding: 12px 100px;
	color: white;
	font-size: 18px;
	outline: none;
	border: none;
	margin-top: 16px;
}

.login-nav {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	height: 44px;
	padding: 10px 100px 10px 52px;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
}

.login-nav .signin-btn {
	display: inline-block;
	background-color: #D59FFF;
	border-radius: 20px;
	padding: 5px 20px;
	color: white;
	font-size: 18px;
	margin-left: 24px;
	font-weight: 700;
	text-decoration: none;
	margin-right: 10px;
}

.login-nav span>span {
	margin: 0 25px;
}

.login-nav span>span::after {
	content: '';
	width: 20px;
	height: 5px;
	background-color: #D59FFF;
	display: inline-block;
	margin-bottom: -14px;
	margin-left: -38px;
	border-radius: 5px;
}

.login-form {
	margin-top: 10px;
}
.recording-editor-section {
	height: 100vh;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	/* justify-content: center; */
	-webkit-align-items: center;
	        align-items: center;
}

.recording-editor-section .nav {
	width: 100%;
	/* background-color: 2D076A !important; */
}

.editor-header {
	/* width: 88%; */
	background-color: black;
	padding: 1% 1%;
	padding-left: 10px;
}

.editor-header span {
	font-size: 0.8rem;
}

.recording-editor-section iframe {
	border: none;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}

.action-bar {
	margin-top: 10%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
	background-color: #21054d;
	padding: 3%;
	border-radius: 5px;
}

.action-bar.student {
	width: 30% !important;
}

.action-bar h1 {
	margin: 0;
}

.middle-row {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.recording-btn {
	background-color: red !important;
}

.play-btn {
	background-color: white !important;
}

.video-div {
	width: 200px;
	height: 50px !important;
	position: absolute;
	top: 0;
	z-index: 100;
}
.outer {
	background: #273138;
	padding: 10px;
	border-radius: 7px 7px 0px 0px;
}

.outer.light {
	background: #f6f6f6;
	/* Old browsers */
	/* FF3.6+ */
	/* Chrome,Safari4+ */
	/* Chrome10+,Safari5.1+ */
	/* Opera 11.10+ */
	/* IE10+ */
	background: linear-gradient(to bottom, #f6f6f6 0%, #dadada 100%);
	/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#dadada', GradientType=0);
	/* IE6-9 */
	border: 1px solid #d8d8d8;
	border-bottom: 1px solid #b4b4b4;
}

.dot {
	display: inline-block;
	width: 10px;
	height: 10px;
	background: #f9f9f9;
	border-radius: 50%;
	margin: 0 4px 0 0;
}

.dot.red {
	background: #ff6057;
	border: 1px solid #e14640;
}

.dot.amber {
	background: #ffbd2e;
	border: 1px solid #dfa123;
}

.dot.green {
	background: #27c93f;
	border: 1px solid #1dad2b;
}

.titlebar {
	width: calc(100% - 50px);
	display: inline-block;
	text-align: center;
	font-weight: 600;
	color: #fff;
	margin-left: -18px;
}

.light .titlebar {
	color: #333;
}

.window-pane {
	height: 60%;
	max-height: 55vh;
	background-color: white;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	padding: 3%;
	color: black;
	font-family: "Inconsolata", monospace;
	font-size: 1.5rem;
	position: relative;
	white-space: pre-wrap;
	overflow: auto;
}
.dash {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	min-height: 100vh;
	background-image: linear-gradient(110.27deg, #3e00a3 0%, #05000e 100%);
	font-family: "Noto Sans JP", sans-serif !important;
}

.login-nav {
	padding-top: 20px;
}

.dash .login-nav span {
	display: -webkit-flex;
	display: flex;
}

.dash .create {
	width: 200px;
	background: linear-gradient(91.9deg, #d59fff 0%, #6a8bff 100%);
	border-radius: 12px;
	padding: 5px 10px;
	color: white;
	font-size: 18px;
	outline: none;
	border: none;
	margin-top: 0px;
	margin-right: 10px;
	cursor: pointer;
	font-family: "Noto Sans JP", sans-serif !important;
}

.avatar {
	width: 40px;
	height: 40px;
	outline: none;
	margin: auto -50px auto 10px;
	border: none;
	border-radius: 50%;
	background-image: url(/static/media/user.8aa3a774.svg);
	background-position: center center;
}

.dash .login-nav .text-field input {
	padding: 12px 16px;
}

.dash .date {
	width: 100%;
	height: 25px;
	padding: 10px 50px;
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 19px;
	color: #9080a9;
}

.dash .courses {
	width: 100%;
	height: 340px;
	padding: 0px 50px 20px 50px;
}

.dash .title {
	font-family: "Noto Sans JP";
	font-size: 34px;
	font-style: normal;
	font-weight: 800;
	line-height: 46px;
	letter-spacing: 0em;
	text-align: left;
}

.dash .courses .cards {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: nowrap;
	        flex-wrap: nowrap;
	-webkit-flex-direction: row;
	        flex-direction: row;
}

.cards .card {
	width: 270px !important;
	height: 300px;
	margin: 5px 15px 5px 0;
	text-align: center;
	background: linear-gradient(180deg, #bc87ff 0%, #8423ff 100%);
	border-radius: 24px;
}

.cards .card:nth-child(2n) {
	background: linear-gradient(0deg, #ff5555 0%, #ffcc91 100%);
}

.cards .card img {
	width: 190px;
	height: 170px;
	object-fit: contain;
}

.cards .card .head {
	font-weight: 600;
	font-size: 22px;
	line-height: 30px;
	color: #f5f5f5;
}

.cards .card .desc {
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	color: #e3e3e3;
}

.cards .card .price {
	font-weight: 600;
	font-size: 26px;
	line-height: 28px;
	color: #fff;
}

.cards .card .lessons {
	font-weight: 800;
	font-size: 12px;
	line-height: 14px;
	color: #e3e3e3;
}

.dash .tutorials {
	width: 40%;
	height: 45vh;
	padding: 40px 50px;
}

.dash .certs {
	width: 40%;
	padding: 40px 50px;
	height: 45vh;
}

.dash .tutorials .cards {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	        flex-wrap: wrap;
	-webkit-flex-direction: row;
	        flex-direction: row;
}

.dash .tutorials .tile {
	width: 200px;
	height: 40px;
	padding: 5px 20px 5px 0;
	margin: 10px 0 10px 0;
}

.dash .tutorials .tile>div {
	height: 40px;
	display: -webkit-inline-flex;
	display: inline-flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: space-around;
	        justify-content: space-around;
	position: absolute;
	padding: 0 10px;
}

.dash .certs .cards img {
	margin: 10px 30px 10px 0;
}
.create-course-modal .MuiPaper-root {
	background-color: #2D076A !important;
	color: white !important;
}

.modal-text-field .MuiInput-root {
	/* background-color: #1f0549 !important; */
}

.modal-text-field .MuiFormLabel-root {
	color: #6b25d3 !important;
}

.modal-text-field .MuiInputBase-input {
	color: white !important;
}

.language-select .MuiSelect-root {
	color: white !important;
}
.lessons-section {
	min-height: 100vh;
	background-color: #2D076A;
}

.hr-text {
	line-height: 1em;
	position: relative;
	outline: 0;
	border: 0;
	color: black;
	text-align: center;
	height: 1.5em;
}

.hr-text:before {
	content: '';
	background: linear-gradient(to right, transparent, #818078, transparent);
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	height: 1px;
}

.hr-text:after {
	content: attr(data-content);
	position: relative;
	display: inline-block;
	color: black;
	padding: 0 .5em;
	line-height: 1.5em;
	background-color: white;
}

.lesson-action-bar {
	width: 100%;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}

.create-lesson {
	width: 200px;
	background: linear-gradient(91.9deg, #d59fff 0%, #6a8bff 100%);
	border-radius: 12px;
	padding: 5px 10px;
	color: white;
	font-size: 18px;
	outline: none;
	border: none;
	margin-top: 0px;
	margin-right: 10px;
	cursor: pointer;
	font-family: "Noto Sans JP", sans-serif !important;
}

.MuiListItemText-secondary {
	color: #a265ff !important;
}
