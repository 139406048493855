.login-page {
	height: 100vh;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	background-repeat: no-repeat;
	background-position: 0% 100%;
	background-size: 100%;
	background-image: url("../../assets/login-bg.svg"), linear-gradient(110.27deg, #3e00a3 0%, #05000e 100%);
	font-family: "Noto Sans JP";
}

.tagline {
	font-family: "Noto Sans JP";
	font-size: 30px;
	font-style: normal;
	font-weight: 700;
	line-height: 41px;
	letter-spacing: 0em;
	text-align: left;
	margin: 120px auto auto 100px;
}

.tagline p {
	display: block;
	font-size: 60px;
	margin: 5px 0;
	line-height: 82px;
}

.tagline p span {
	color: #df9ffe;
}

.text-field {
	background-color: #2c0078;
	border-radius: 12px;
	color: #fff;
	margin: 8px 0 !important;
}

.text-field input {
	color: #fff;
	outline: none;
	border: none;
	border-radius: 12px !important;
}

option {
	height: 20px;
	padding: 10px 15px;
}

.text-field div {
	border-radius: 12px !important;
	color: white;
}

.login-btn {
	width: 100%;
	background: linear-gradient(91.9deg, #d59fff 0%, #6a8bff 100%);
	border-radius: 12px;
	padding: 12px 100px;
	color: white;
	font-size: 18px;
	outline: none;
	border: none;
	margin-top: 16px;
}

.login-nav {
	width: 100%;
	display: flex;
	height: 44px;
	padding: 10px 100px 10px 52px;
	justify-content: space-between;
	align-items: center;
}

.login-nav .signin-btn {
	display: inline-block;
	background-color: #D59FFF;
	border-radius: 20px;
	padding: 5px 20px;
	color: white;
	font-size: 18px;
	margin-left: 24px;
	font-weight: 700;
	text-decoration: none;
	margin-right: 10px;
}

.login-nav span>span {
	margin: 0 25px;
}

.login-nav span>span::after {
	content: '';
	width: 20px;
	height: 5px;
	background-color: #D59FFF;
	display: inline-block;
	margin-bottom: -14px;
	margin-left: -38px;
	border-radius: 5px;
}

.login-form {
	margin-top: 10px;
}