.outer {
	background: #273138;
	padding: 10px;
	border-radius: 7px 7px 0px 0px;
}

.outer.light {
	background: #f6f6f6;
	/* Old browsers */
	background: -moz-linear-gradient(top, #f6f6f6 0%, #dadada 100%);
	/* FF3.6+ */
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		color-stop(0%, #f6f6f6),
		color-stop(100%, #dadada)
	);
	/* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, #f6f6f6 0%, #dadada 100%);
	/* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, #f6f6f6 0%, #dadada 100%);
	/* Opera 11.10+ */
	background: -ms-linear-gradient(top, #f6f6f6 0%, #dadada 100%);
	/* IE10+ */
	background: linear-gradient(to bottom, #f6f6f6 0%, #dadada 100%);
	/* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f6f6', endColorstr='#dadada', GradientType=0);
	/* IE6-9 */
	border: 1px solid #d8d8d8;
	border-bottom: 1px solid #b4b4b4;
}

.dot {
	display: inline-block;
	width: 10px;
	height: 10px;
	background: #f9f9f9;
	border-radius: 50%;
	margin: 0 4px 0 0;
}

.dot.red {
	background: #ff6057;
	border: 1px solid #e14640;
}

.dot.amber {
	background: #ffbd2e;
	border: 1px solid #dfa123;
}

.dot.green {
	background: #27c93f;
	border: 1px solid #1dad2b;
}

.titlebar {
	width: calc(100% - 50px);
	display: inline-block;
	text-align: center;
	font-weight: 600;
	color: #fff;
	margin-left: -18px;
}

.light .titlebar {
	color: #333;
}
