.lessons-section {
	min-height: 100vh;
	background-color: #2D076A;
}

.hr-text {
	line-height: 1em;
	position: relative;
	outline: 0;
	border: 0;
	color: black;
	text-align: center;
	height: 1.5em;
}

.hr-text:before {
	content: '';
	background: linear-gradient(to right, transparent, #818078, transparent);
	position: absolute;
	left: 0;
	top: 50%;
	width: 100%;
	height: 1px;
}

.hr-text:after {
	content: attr(data-content);
	position: relative;
	display: inline-block;
	color: black;
	padding: 0 .5em;
	line-height: 1.5em;
	background-color: white;
}

.lesson-action-bar {
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.create-lesson {
	width: 200px;
	background: linear-gradient(91.9deg, #d59fff 0%, #6a8bff 100%);
	border-radius: 12px;
	padding: 5px 10px;
	color: white;
	font-size: 18px;
	outline: none;
	border: none;
	margin-top: 0px;
	margin-right: 10px;
	cursor: pointer;
	font-family: "Noto Sans JP", sans-serif !important;
}

.MuiListItemText-secondary {
	color: #a265ff !important;
}