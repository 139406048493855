.window-pane {
	height: 60%;
	max-height: 55vh;
	background-color: white;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	padding: 3%;
	color: black;
	font-family: "Inconsolata", monospace;
	font-size: 1.5rem;
	position: relative;
	white-space: pre-wrap;
	overflow: auto;
}